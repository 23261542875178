<template>
    <button :title="title" :type="triggerType" :class="['custom-action-button', typeClass, styleClasses, { 'disabled': isDisabled }]" @click="emitClick()">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "ActionButton",
    props: {
        type: {
            type: String,
            default: 'system',
            validator(value) {
                return ['system', 'primary', 'secondary', 'success', 'danger', 'warning', 'info'].includes(value);
            }
        },
        title: {
            type: String,
            default: '',
        },
        triggerType: {
            type: String,
            default: 'button',
            validator(value) {
                return ['button', 'submit', 'reset'].includes(value);
            }
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        className: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        typeClass() {
            return `${this.type}`;
        },
        styleClasses() {
            return this.className.length > 0 ? this.className.join(' ') : '';
        }
    },
    methods: {
        emitClick() {
            this.$emit('handle-click');
        },
    }
};
</script>


<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-action-button {
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    // border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.custom-action-button.system {
    background-color: #004a7c;
}

/* Primary */
.custom-action-button.primary {
    background-color: #007bff;
}

.custom-action-button.primary:hover {
    background-color: #0069d9;
}

.custom-action-button.primary:active {
    background-color: #0056b3;
}

/* Secondary */
.custom-action-button.secondary {
    background-color: #6c757d;
}

.custom-action-button.secondary:hover {
    background-color: #5a6268;
}

.custom-action-button.secondary:active {
    background-color: #4e555b;
}

/* Success */
.custom-action-button.success {
    background-color: #28a745;
}

.custom-action-button.success:hover {
    background-color: #218838;
}

.custom-action-button.success:active {
    background-color: #1e7e34;
}

/* Danger */
.custom-action-button.danger {
    background-color: #dc3545;
}

.custom-action-button.danger:hover {
    background-color: #c82333;
}

.custom-action-button.danger:active {
    background-color: #bd2130;
}

/* Warning */
.custom-action-button.warning {
    background-color: #ffc107;
    color: #212529;
}

.custom-action-button.warning:hover {
    background-color: #e0a800;
}

.custom-action-button.warning:active {
    background-color: #d39e00;
}

/* Info */
.custom-action-button.info {
    background-color: #17a2b8;
}

.custom-action-button.info:hover {
    background-color: #138496;
}

.custom-action-button.info:active {
    background-color: #117a8b;
}

/* Focus effect */
.custom-action-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
</style>