<template>
  <div>
    <span v-if="type" :class="['custom-badge', typeClass]" :style="(type == 'custom' && color != '') ? { backgroundColor: color } : {}" >
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'custom'].includes(value);
      }
    },
    color: {
      type: String,
      default: '',
    }
  },
  computed: {
    typeClass() {
      return `custom-badge-${this.type}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  border-radius: 0.25rem;
}

.custom-badge-primary {
  background-color: #007bff;
}

.custom-badge-secondary {
  background-color: #6c757d;
}

.custom-badge-success {
  background-color: #28a745;
}

.custom-badge-danger {
  background-color: #dc3545;
}

.custom-badge-warning {
  background-color: #ffc107;
}

.custom-badge-info {
  background-color: #17a2b8;
}
</style>
  