<template>
    <div class="form-preloader">
        <div class="preloader-spinner">
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="preloader-text">
            {{ text }}
        </div>
    </div>
</template>

<script>

export default {
    name: "Loader",
    props: {
        text: {
            type: String,
            default:"Loading"
        }
    }
};
</script>

<style lang="scss" scoped>
.form-preloader {
    position: fixed;
    z-index: 999;
    width: calc(100vw - 320px);
    height: calc(100vh - 140px);
    backdrop-filter: blur(5px);
    margin: 0 0 0 -19px;

    .preloader-spinner {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .preloader-text {
        margin-top: 20px;
        position: absolute;
        font-size: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
