<template>
    <div class="table-responsive">
        <table class="custom-table">
            <thead>
                <tr>
                    <!-- <th>Invoice Number</th> -->
                    <th>Transaction Date</th>
                    <th>Txn Type</th>
                    <th>AWB No/Job No</th>
                    <!-- <th>Extra Charges (XC)</th> -->
                    <th>Invoice Amount</th>
                    <th>Reconcile Status</th>
                    <th>Files</th>
                </tr>
            </thead>

            <tbody>
                <template v-if="invoice_data.length > 0">
                    <tr v-for="(invoice, i) in invoice_data">
                        <!-- <td>{{ invoice.invoice_number }}</td> -->
                        <td>{{ invoice.invoice_date }}</td>
                        <td>{{ formatLabel(invoice.category) }}</td>
                        <td>{{ formatBookingRef(invoice.airway_bill_no, invoice.job_no) }}</td>
                        <!-- <td>
                            <ul>
                                <template v-if="invoice.extra_charges.length != 0">
                                    <li v-for="(charge, c) in invoice.extra_charges"
                                        :key="`${invoice.airway_bill}-${c}`">
                                        {{ `${capitalizeFirstLetter(charge.description)} : $ ${charge.total_ex_gst}` }}
                                    </li>
                                </template>
                                <template v-else>
                                    <li>{{ '--' }}</li>
                                </template>
                            </ul>
                        </td>                         -->
                        <td>{{ `$ ${invoice.invoice_value_ex_gst}` }}</td>
                        <td>
                            <template v-if="invoice.duplicate_invoice && invoice.duplicate_invoice.invoice">
                                <Badge :type="invoice.duplicate_invoice.invoice.is_reconciled ? 'success' : 'danger'">
                                    {{ invoice.duplicate_invoice.invoice.is_reconciled ? 'Reconciled ' : 'Not Reconciled' }}
                                </Badge>
                            </template>
                        </td>
                        <td style="text-align: start;">
                            <template v-if="invoice.duplicate_invoice && invoice.duplicate_invoice.files">
                                <p v-for="(file, f) in invoice.duplicate_invoice.files">
                                    {{ file.file_name }}
                                </p>
                            </template>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="6">No Invoice Data Found</td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>
</template>
<script>
import moment from "moment";
import Badge from "../components/Badge.vue";
import ActionButton from "../components/ActionButton.vue";

export default {
    name: "DuplicatedInvoiceDataTable",
    props: {
        invoice_data: {
            required: true
        },
        invoice_type: {
            required: true
        },
    },
    components: {
        ActionButton,
        Badge,
    },
    filters: {
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('DD/MM/YYYY')
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            if (!string) return string;

            return string
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        },
        formatLabel(value) {
            const labelMap = {
                courier_charge: "Courier Charge",
                booking: "Booking",
                extra_charges: "Extra Charge"
            };
            return labelMap[value] || value;
        },
        formatBookingRef(airwayBillNo, jobNo) {
            if (!airwayBillNo && !jobNo) {
                return '';
            } else if (!airwayBillNo) {
                return jobNo;
            } else if (!jobNo) {
                return airwayBillNo;
            } else if (airwayBillNo === jobNo) {
                return airwayBillNo;
            } else {
                return `${airwayBillNo} / ${jobNo}`;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
// custome-table
.table-responsive {
    overflow-x: auto;
    margin: 20px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    padding-inline: 5px;
    padding-block: 12px;
    border: 1px solid #dee2e6;
    text-align: center;
}

.custom-table th {
    background-color: #f8f9fa;
    color: #495057;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tr:hover {
    background-color: #e9ecef;
}

.custom-table .action-btns>.custom-action-button {
    margin-right: 5px;
}

.custom-table .action-btns>.custom-action-button:last-child {
    margin-right: 0 !important;
}
</style>